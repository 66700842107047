<template>
  <div id="mortgageCmhcCalculator">
    <!-- Placeholder for the widget -->
    <div ref="widgetContainer"></div>
    <!-- Attribution link -->
    <div style="text-align:right; font-size:0.8em">
      <span style="font-family:sans-serif; color:#6a787c; display:inline-block;">powered by
        <a rel="nofollow" href="https://www.ratehub.ca/?offer_id=2" style="display:inline-block;width:6em;">
          <img src="https://www.ratehub.ca/static/logo_ratehub_full_dark.svg" style="width:100%; vertical-align:text-top;" alt="Ratehub.ca">
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MortgageCmhcCalculator',
  mounted() {
    this.loadWidgetScript();
  },
  methods: {
    loadWidgetScript() {
      const script = document.createElement('script');
      script.src = 'https://www.ratehub.ca/scripts/rh-widget-loader.js';
      script.setAttribute('rh-title', 'Mortgage CMHC Calculator');
      script.setAttribute('rh-frame-title', 'Ratehub.ca mortgage cmhc calculator');
      script.setAttribute('rh-widget-key', 'DownPaymentCalculator');
      script.onload = () => console.log('Widget script loaded successfully');
      script.onerror = () => console.error('Error loading the widget script');
      this.$refs.widgetContainer.appendChild(script);
    }
  }
}
</script>

<style scoped>
/* Add any specific styles for your widget container here */
</style>
